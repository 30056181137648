/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-device-event-log-grid-panel {
	@include flexbox-container(column, nowrap, flex-start, stretch);

	> .sp-panel {
		@include flexbox-item(column, 1, 1);

		> .sp-panel-body {
			@include flexbox-container(row, nowrap, stretch, stretch);

			> .sp-virtualgrid-wrapper {
				@include flexbox-item(row, 1, 1);
				@include scrollable-container;

				> .sp-virtualgrid {
					@include flexbox-container(column);
					@include scrollable-item;
				}
			}
		}
	}
}
