/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

@import "../../../../../platform/lib/webapp/reports/archive-record-report";

.sp-archive-record-report-header {
	@include flexbox-item(column, 0, 0, 75px, 75px);
	@include flexbox-container(column, nowrap, flex-end, center);
	@include font-t(2);
	padding-bottom: .5em;
	position: relative;

	img {
		height: 1em;
		position: absolute;
		top: 0;
	}
}
