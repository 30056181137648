/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

.sp-line-inventory-table-title {
	@include flexbox-container(row, nowrap, flex-start, center);

	> span:nth-child(2) {
		font-weight: bold;
	}
}

.sp-line-inventory-table {
	> .sp-line-inventory-table-header-row,
	> .sp-line-inventory-table-row,
	> .sp-line-inventory-table-footer-row {
		@include flexbox-container(row, nowrap, flex-start, stretch, stretch);
		background-color: $background-color-content;
		break-inside: avoid;
		min-height: 1.5em;
		min-width: 100%;
		page-break-inside: avoid;

		> .sp-line-inventory-table-column {
			@include flexbox-item(row, 0, 0, auto, auto, auto);
			@include flexbox-container(row, wrap, flex-start, center, stretch);
			padding: .25em .5em .25em 0;

			&:first-child {
				padding: .25em .5em;
			}
		}
	}

	> .sp-line-inventory-table-header-row {
		border-bottom: 1px solid $border-color;

		> .sp-line-inventory-table-column {
			> span {
				font-style: italic;
				padding-right: .25em;
			}

			> span:nth-child(2) {
				color: $color-grey-45;
			}
		}
	}

	> .sp-line-inventory-table-row {
		position: relative;

		&:nth-child(2n+0) {
			background-color: $background-color-content-alternate;
		}

		&.sp-hover {
			cursor: pointer;

			&:hover {
				background-color: $background-color-selected;
				color: $color-selected-text;

				.sp-icon {
					color: $color-selected-text;
				}
			}
		}

		&.sp-error:hover {
			color: $color-selected-text !important;
		}

		.sp-line-inventory-table-row-error {
			height: 0;
			left: 0;
			overflow: hidden;
			padding-right: 1em;
			padding-top: 1em;
			position: absolute;
			top: 0;
			width: 0;
		}

		.sp-line-inventory-table-row-error::after {
			border-right: 1em solid transparent;
			border-top: 1em solid $color-error;
			content: "";
			display: block;
			height: 0;
			margin-right: -1em;
			margin-top: -1em;
			width: 0;
		}
	}

	> .sp-line-inventory-table-footer-row {
		@include flexbox-container(row, nowrap, center, center, stretch);
		font-style: italic;
		height: 3em;
	}
}

@media print {
	.sp-line-inventory-table {
		@include flexbox-container(column, wrap);

		> .sp-line-inventory-table-header-row,
		> .sp-line-inventory-table-row,
		> .sp-line-inventory-table-footer-row {
			@include flexbox-item(column, 0, 0);
			@include flexbox-flow(row, wrap);
		}

		> .sp-line-inventory-table-row .sp-line-inventory-table-row-error {
			display: none;
		}
	}
}

@media screen {
	.sp-line-inventory-table {
		overflow: auto;

		> .sp-line-inventory-table-header-row,
		> .sp-line-inventory-table-row,
		> .sp-line-inventory-table-footer-row {
			float: left;
		}
	}
}

@mixin column-width($name, $width, $grow: 1, $align: left) {
	.sp-line-inventory-table > .sp-line-inventory-table-header-row .sp-line-inventory-table-column.#{$name},
	.sp-line-inventory-table > .sp-line-inventory-table-row .sp-line-inventory-table-column.#{$name},
	.sp-line-inventory-table > .sp-line-inventory-table-footer-row .sp-line-inventory-table-column.#{$name} {
		@include flexbox-item(row, $grow, 0, $width, $width, auto);
		@if $align == right {
			justify-content: flex-end;
		}
	}
}

.sp-line-inventory-overview {
	@include flexbox-container(column);
}

.sp-line-inventory-totals {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap);
	padding: 1em;

	> .sp-line-inventory-table-title {
		@include flexbox-item(column, 0, 0, 2em, 2em);
	}

	> .sp-line-inventory-table {
		@include flexbox-item(column, 0, 0);
	}

	@include column-width(grade, 12em, 0);
	@include column-width(gv-open, 10em, 0, right);
	@include column-width(gv-delta, 10em, 0, right);
	@include column-width(direction, 1em, 0, left);
	@include column-width(gv-close, 10em, 0, right);

	.sp-line-inventory-table-row .gv-open > span,
	.sp-line-inventory-table-row .gv-delta > span,
	.sp-line-inventory-table-row .gv-close > span {
		font-size: 1.5em;
	}

	.sp-line-inventory-table-row .direction {
		> span {
			font-size: 1.5em;
		}

		> .xl-icon-up {
			color: $color-accent-3;
		}

		> .xl-icon-down {
			color: $color-accent-5;
		}
	}

	.nsv-close {
		padding-right: 8em;
	}

	.nsv-in,
	.nsv-out,
	.nsv-adjust {
		color: $color-grey-45;
	}
}

.sp-line-inventory-records {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap);
	padding: 1em;

	> .sp-line-inventory-table-title {
		@include flexbox-item(column, 0, 0, 2em, 2em);
	}

	> .sp-line-inventory-table {
		@include flexbox-item(column, 0, 0);
	}

	@include column-width(date, 9em, 0);
	@include column-width(pipeline, 6em, 0);
	@include column-width(segment, 6em, 0);
	@include column-width(grade1, 10.5em, 1);
	@include column-width(gv1, 5.5em, 0, right);
	@include column-width(grade2, 10.5em, 1);
	@include column-width(gv2, 5.5em, 0, right);
	@include column-width(grade3, 10.5em, 1);
	@include column-width(gv3, 5.5em, 0, right);
	@include column-width(grade4, 10.5em, 1);
	@include column-width(gv4, 5.5em, 0, right);
	@include column-width(edit, 1em, 0);

	.direction {
		padding-right: 2em;

		> span {
			font-size: 1em;

			&.xl-icon-in {
				color: $color-accent-3;
			}

			&.xl-icon-out {
				color: $color-accent-5;
			}
		}
	}

	.running {
		font-style: italic;
		font-weight: bold;
	}

	.sp-line-inventory-table-row .edit {
		color: $color-text;
		padding-left: .5em;

		&:not(:empty):hover {
			background-color: $color-accent-brand-dark;
		}
	}
}

.sp-line-inventory-adjustments {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap);
	padding: 1em;

	> .sp-line-inventory-table-title {
		@include flexbox-item(column, 0, 0, 2em, 2em);
	}

	> .sp-line-inventory-table {
		@include flexbox-item(column, 0, 0);
	}

	@include column-width(grade, 12em, 1, left);
	@include column-width(gv, 6em, 0, right);
	@include column-width(comment, 8em, 8);
	@include column-width(edit, 1em, 0);

	.sp-line-inventory-table-row > .sp-line-inventory-table-column.gv,
	.sp-line-inventory-table-header-row > .sp-line-inventory-table-column.gv {
		padding-right: 4em;
	}

	.sp-line-inventory-table-row .edit {
		color: $color-text;
		padding-left: .5em;

		&:not(:empty):hover {
			background-color: $color-accent-brand-dark;
			cursor: pointer;

			.sp-icon {
				color: $color-selected-text;
			}
		}
	}
}

@media (max-width: 90em) {
	.sp-line-inventory-records {
		@include column-width(date, 7.5em, 0);
		@include column-width(pipeline, 4.5em, 0);
		@include column-width(segment, 4.5em, 0);
		@include column-width(grade1, 6em, 0);
		@include column-width(gv1, 4.5em, 0, right);
		@include column-width(grade2, 6em, 0);
		@include column-width(gv2, 4.5em, 0, right);
		@include column-width(grade3, 6em, 0);
		@include column-width(gv3, 4.5em, 0, right);
		@include column-width(grade4, 6em, 0);
		@include column-width(gv4, 4.5em, 0, right);

		.sp-line-inventory-table-column span {
			font-size: .875em;
		}
	}
}

@media (max-width: 75em) {
	.sp-line-inventory-totals {
		@include column-width(grade, 12em, 0);
		@include column-width(gv-open, 10em, 0, right);
		@include column-width(gv-delta, 10em, 0, right);
		@include column-width(direction, 1em, 0, left);
		@include column-width(gv-close, 10em, 0, right);

	}
}

@media (max-width: 65em) {
	.sp-line-inventory-adjustments {
		@include column-width(grade, 10.5em, 1, left);
		@include column-width(gv, 3.5em, 0, right);
		@include column-width(comment, 8em, 8);

		.gv {
			padding-right: 2em;
		}
	}

	.sp-line-inventory-records .direction {
		display: none !important;
	}
}

@media print {
	.sp-line-inventory-totals,
	.sp-line-inventory-records,
	.sp-line-inventory-adjustments {
		padding: 1em 0 !important;
	}

	.sp-line-inventory-records .direction {
		display: none !important;
	}
}
