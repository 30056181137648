/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-device-report-view-dialog {

	> .sp-fmid-device-report-view {

		> .sp-device-report-view-header {
			@include flexbox-item(column, 0, 0, 3.5em, 3.5em);
			@include flexbox-container(column, nowrap, center, center);
			@include font-t(2);
			position: relative;

			> .sp-device-report-view-logo {
				@include flexbox-item(column, 0, 0, 1em, 1em);
				position: relative;
			}

			> .sp-device-report-view-title {
				@include flexbox-item(column, 0, 0);
				margin-top: .5em;
			}
		}
	}
}
