/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

@import "../../../../platform/lib/webapp/core/index"; // MUST BE FIRST

// DO NOT ADD OTHER IMPORT STATEMENTS HERE. ADD THEM AT THE BOTTOM.

.all-content {
	@include flexbox-container(column);

	> .sp-application {
		@include flexbox-item(column, 1, 0);
	}
}

@media screen  {
	body {
		background-color: $background-color-overall;
		color: $color-text;
		font-family: $fontfamily-normal;
		font-size: 100%; // needed to be able to specify the rest in "em"
	}

	.all-content {
		height: 100vh;
		overflow: auto;
		width: 100vw;

		> .sp-application {
			@include flexbox-item(column, 0, 0, 100%, 100%);
		}
	}
}

@media print {
	body {
		background-color: $background-color-overall-print;
		color: $color-text-print;
		font-family: $fontfamily-normal;
		font-size: 12pt;
	}

	// In printing, each div directly inside the body is treated as a layer and only the
	// top-most layer (last child in the DOM tree) will be visible.
	body > div:not(:last-of-type) {
		display: none !important;
	}
}

// Sub stylesheets LAST!!

// Common widgets
@import "../../../../platform/lib/webapp/app/index";
@import "../../../../platform/lib/webapp/auth/index";
@import "../../../../platform/lib/webapp/device-info/index";
@import "../../../../platform/lib/webapp/etl/index";
@import "../../../../platform/lib/webapp/events/index";
@import "../../../../platform/lib/webapp/navigation/index";
@import "../../../../platform/lib/webapp/poller/index";

// Fairway specific

@import "./etl/index";
@import "./reports/archive-record-report";
@import "./special/index";
@import "./Help";
