/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

.sp-periodic-report-generate-dialog-sections {
	.sp-accordion-item-content:last-child {
		@include flexbox-item(column, 1, 1);
	}
}

.sp-periodic-report-generate-dialog-section-what-where {
	.sp-dialog-column:first-child {
		.sp-input-label:first-child {
			@include flexbox-item(column, 1, 1);

			.sp-check-box-group-input {
				@include flexbox-item(column, 1, 1);
			}
		}
	}

	.sp-dialog-column:last-child {
		.sp-input-label:nth-child(2) {
			@include flexbox-item(column, 1, 1);

			.sp-check-box-group-input {
				@include flexbox-item(column, 1, 1);
			}
		}
	}
}

.sp-periodic-report-generate-result {
	table {
		width: 100%;

		thead {
			font-weight: bold;
		}

		thead,
		tbody {
			border-bottom: $inner-border;
		}

		tr {
			height: 1.5em;
		}

		td,
		th {
			vertical-align: middle;

			&:nth-child(1) {
				text-align: left;
				width: 60%;
			}

			&:nth-child(2),
			&:nth-child(3) {
				text-align: right;
				width: 20%;
			}
		}

		tfoot {
			font-style: italic;
			font-weight: bold;
		}
	}
}

.sp-periodic-report-generate-explanation {
	@include flexbox-item(column, 1, 0);
	@include font-t(7);
	color: $input-label-color;
	margin: 1em 0;
	width: 100%;
}
