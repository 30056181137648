/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

.sp-over-short-table-title {
	@include flexbox-container(row, nowrap, flex-start, center);

	> span:nth-child(2) {
		font-weight: bold;
	}
}

.sp-over-short-table {
	.sp-over-short-table-header-row,
	.sp-over-short-table-row,
	.sp-over-short-table-footer-row {
		@include flexbox-container(row, nowrap, flex-start, stretch, stretch);
		background-color: $background-color-content;
		break-inside: avoid;
		min-height: 1.5em;
		min-width: 100%;
		page-break-inside: avoid;
	}

	.sp-over-short-table-cell {
		@include flexbox-item(row, 0, 0, auto, auto, auto);
		@include flexbox-container(row, wrap, flex-start, center, stretch);
		padding: .25em .5em .25em 0;

		&:first-child {
			padding: .25em .5em;
		}

		&.sp-error {
			color: $color-error;
		}
	}

	.sp-over-short-table-header-row {
		border-bottom: 1px solid $border-color;

		.sp-over-short-table-cell {
			> span {
				font-style: italic;
				padding-right: .25em;
			}

			> span:nth-child(2) {
				color: $color-grey-45;
			}
		}
	}

	> .sp-over-short-table-row {
		&:nth-child(2n+0) {
			background-color: $background-color-content-alternate;
		}

		&.sp-hover {
			cursor: pointer;

			&:hover {
				background-color: $background-color-selected;
				color: $color-selected-text;

				.sp-icon {
					color: $color-selected-text;
				}
			}
		}
	}

	> .sp-over-short-table-footer-row {
		@include flexbox-container(row, nowrap, center, center, stretch);
		font-style: italic;
		height: 3em;
	}
}

@media print {
	.sp-over-short-table {
		@include flexbox-container(column, wrap);

		> .sp-over-short-table-header-row,
		> .sp-over-short-table-row,
		> .sp-over-short-table-footer-row {
			@include flexbox-item(column, 0, 0);
			@include flexbox-flow(row, wrap);
		}
	}
}

@media screen {
	.sp-over-short-table {
		overflow: auto;

		> .sp-over-short-table-header-row,
		> .sp-over-short-table-row,
		> .sp-over-short-table-footer-row {
			float: left;
		}
	}
}

@mixin column-width($name, $width, $grow: 1, $align: left) {
	.sp-over-short-table > .sp-over-short-table-header-row .sp-over-short-table-cell.#{$name},
	.sp-over-short-table > .sp-over-short-table-row .sp-over-short-table-cell.#{$name},
	.sp-over-short-table > .sp-over-short-table-footer-row .sp-over-short-table-cell.#{$name} {
		@include flexbox-item(row, $grow, 0, $width, $width, auto);
		@if $align == right {
			justify-content: flex-end;
		} @else if $align == center {
			justify-content: center;
		}
	}
}

.sp-over-short-overview-overview {
	@include flexbox-container(column);
}

.sp-over-short-totals-one-grade {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap);
	padding: 1em;

	> .sp-over-short-table-title {
		@include flexbox-item(column, 0, 0, 2em, 2em);
	}

	> .sp-over-short-table {
		@include flexbox-item(column, 0, 0);

		> .sp-over-short-table-row {
			&:nth-child(2n+0) {
				background-color: initial;
			}

			&:nth-child(3n+0) {
				background-color: $background-color-content-alternate;
			}

			&:nth-child(3n+2) {
				background-color: $color-grey-70;
			}

			&:nth-child(5) {
				font-weight: bold;
			}

		}
	}

	@include column-width(grade, 12em, 0);
	@include column-width(open, 7em, 0, right);
	@include column-width(delta, 7em, 0, right);
	@include column-width(close, 7em, 0, right);
	@include column-width(in, 7em, 0, right);
	@include column-width(out, 7em, 0, right);
	@include column-width(adjust, 7em, 0, right);
}

.sp-over-short-inventory-statuses {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap);
	padding: 1em;

	> .sp-over-short-table-title {
		@include flexbox-item(column, 0, 0, 2em, 2em);
	}

	> .sp-over-short-table {
		@include flexbox-item(column, 0, 0);

		> .sp-over-short-table-row {
			&:nth-child(2n+0) {
				background-color: initial;
			}

			&:nth-child(3n+0) {
				background-color: $background-color-content-alternate;
			}

			&:nth-child(3n+2) {
				background-color: $color-grey-70;
			}
		}
	}

	@include column-width(inventory, 8em, 0);
	@include column-width(status, 6em, 0, center);
}


.sp-over-short-totals-all-grades {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap);
	padding: 1em;

	> .sp-over-short-table-title {
		@include flexbox-item(column, 0, 0, 2em, 2em);
	}

	> .sp-over-short-table {
		@include flexbox-item(column, 0, 0);

		> .sp-over-short-table-row {
			&:nth-child(2n+0) {
				background-color: initial;
			}

			&:nth-child(3n+0) {
				background-color: $background-color-content-alternate;
			}

			&:nth-child(3n+2) {
				background-color: $color-grey-70;
			}
		}
	}

	@include column-width(grade, 12em, 0);
	@include column-width(cavern-gsv, 7em, 0, right);
	@include column-width(line-gv, 7em, 0, right);
	@include column-width(customer-gsv, 7em, 0, right);
	@include column-width(over-short, 7em, 0, right);
}

@media (max-width: 75em) {
	.sp-over-short-overview-totals-one-grade {
		@include column-width(grade, 6em, 0);
		@include column-width(open, 6em, 0, right);
		@include column-width(delta, 6em, 0, right);
		@include column-width(close, 6em, 0, right);
		@include column-width(in, 6em, 0, right);
		@include column-width(out, 6em, 0, right);
		@include column-width(adjust, 6em, 0, right);
	}

	.sp-over-short-overview-totals-all-grades {
		@include column-width(grade, 6em, 0);
		@include column-width(cavern-gsv, 6em, 0, right);
		@include column-width(line-gv, 6em, 0, right);
		@include column-width(customer-gsv, 6em, 0, right);
		@include column-width(over-short, 6em, 0, right);
	}

	.sp-over-short-inventory-statuses {
		@include column-width(inventory, 6em, 0);
		@include column-width(status, 5em, 0, center);
	}
}

@media print {
	.sp-over-short-overview-totals-one-grade,
	.sp-over-short-overview-inventory-statuses,
	.sp-over-short-overview-totals-all-grades {
		padding: 1em 0 !important;
	}
}
