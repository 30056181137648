/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

.sp-customer-inventory {
	@include flexbox-container(column, nowrap);

	> .sp-customer-inventory-header {
		@include flexbox-container(row, wrap);
		@include flexbox-item(column, 0, 0);
		padding-left: 2em;

		> .sp-customer-inventory-drop-down {
			@include flexbox-container(row, nowrap);
			@include flexbox-item(row, 1, 1, calc(50% - 4em), calc(50% - 4em));
			box-sizing: border-box;
			min-width: 25em;
			padding-right: 2em;
			padding-top: 2em;

			> .sp-customer-inventory-drop-down-button {
				@include flexbox-item(row, 1, 0);
				@include flexbox-container(row, nowrap);
				border-bottom: 2px solid $border-color;
				border-top: 2px solid $border-color;
				cursor: pointer;
				height: 2em;
				outline: 0;

				> div:nth-child(1) {
					@include flexbox-item(row, 1, 1, 50%, 50%);
					@include flexbox-container(column, nowrap, center, flex-end);
					padding-right: .5em;

					> span {
						@include font-t(3);
					}
				}

				> div:nth-child(2) {
					@include flexbox-item(row, 1, 1, 50%, 50%);
					@include flexbox-container(column, nowrap, center, flex-start);
					min-width: 16em;
					padding-right: .5em;

					> span {
						@include font-t(4);

						&.sp-invalid {
							color: $color-error-text;
						}
					}
				}

				> div:nth-child(3) {
					@include flexbox-item(row, 0, 0, 2em, 2em);
					@include flexbox-container(column, nowrap, center, center);

					> span {
						font-size: 1.5em;
					}
				}

				&:focus > div:nth-child(3),
				&:hover > div:nth-child(3) {
					color: $color-accent-brand-dark;
				}
			}

			> .sp-overlay {
				max-width: 50em;
				min-width: 25em;
				width: 50%;

				> .sp-customer-inventory-drop-down-overlay {
					@include flexbox-container(column, nowrap);
					@include box-shadow($default-box-shadow);
					background-color: $background-color-content;
					margin-top: .25em;
					pointer-events: all;

					> .sp-customer-inventory-drop-down-error {
						@include flexbox-item(column, 0, 0, 2em, 2em);
						@include flexbox-container(column, nowrap, center, center);
						border-top: $inner-border;
						color: $color-error-text;
						padding: 0 1em;

						&:first-child {
							border-top: 0;
						}
					}

					> .sp-date-picker {
						@include flexbox-item(column, 0, 0);
						border-bottom: $inner-border;
						border-top: $inner-border;
					}

					> .sp-customer-inventory-drop-down-options {
						@include flexbox-item(column, 0, 0);
						@include flexbox-container(column, nowrap);

						> .sp-customer-inventory-drop-down-option {
							@include flexbox-item(column, 0, 0, 2em, 2em);
							@include flexbox-container(column, nowrap, center, center);
							border-top: $inner-border;
							cursor: pointer;
							outline: 0;
							padding: 0 1em;

							&:first-child {
								border-top: 0;
							}

							&.sp-active {
								background-color: $color-accent-brand-dark;
								color: $color-white;
							}

							&:focus,
							&.sp-active:focus,
							&:hover,
							&.sp-active:hover {
								background-color: $color-accent-brand;
								color: $color-white;
							}
						}

						&.sp-disabled {
							color: $color-disabled-text;
							pointer-events: none;
						}
					}
				}
			}
		}
	}

	> .sp-customer-inventory-error {
		@include flexbox-item(column, 1, 1);
		@include flexbox-container(column, nowrap, center, center);

		> span {
			@include font-t(3);
			color: $color-grey-45;
			font-style: italic;
		}
	}

	> .sp-panel {
		@include flexbox-item(column, 0, 0);
		margin: 2em;
	}

	.sp-customer-inventory-overview {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(column);
	}

	.sp-loading-indicator {
		@include flexbox-item(column, 1, 1);
	}
}

@media print {
	.sp-customer-inventory > .sp-customer-inventory-header {
		padding-left: 0;

		> .sp-customer-inventory-drop-down {
			padding: 0;

			> .sp-customer-inventory-drop-down-button {
				border: 0;

				> div:nth-child(3) {
					display: none;
				}
			}

			> .sp-overlay {
				display: none;
			}
		}
	}

	.sp-customer-inventory > .sp-panel {
		border: 0;
		padding: 0;
	}
}
