/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

.sp-cavern-inventory-table-title {
	@include flexbox-container(row, nowrap, flex-start, center);

	> span:nth-child(2) {
		font-weight: bold;
	}
}

.sp-cavern-inventory-table {
	.sp-cavern-inventory-table-header-row,
	.sp-cavern-inventory-table-row,
	.sp-cavern-inventory-table-footer-row {
		@include flexbox-container(row, nowrap, flex-start, stretch, stretch);
		background-color: $background-color-content;
		break-inside: avoid;
		min-height: 1.5em;
		min-width: 100%;
		page-break-inside: avoid;
	}

	.sp-cavern-inventory-table-column {
		@include flexbox-item(row, 0, 0, auto, auto, auto);
		@include flexbox-container(row, wrap, flex-start, center, stretch);
		padding: .25em .5em .25em 0;

		&:first-child {
			padding: .25em .5em;
		}
	}

	.sp-cavern-inventory-table-header-row {
		border-bottom: 1px solid $border-color;

		.sp-cavern-inventory-table-column {
			> span {
				font-style: italic;
				padding-right: .25em;
			}

			> span:nth-child(2) {
				color: $color-grey-45;
			}
		}
	}

	> .sp-cavern-inventory-table-row {
		position: relative;

		&:nth-child(2n+0) {
			background-color: $background-color-content-alternate;
		}

		&.sp-hover {
			cursor: pointer;

			&:hover {
				background-color: $background-color-selected;
				color: $color-selected-text;

				.sp-icon {
					color: $color-selected-text;
				}
			}
		}

		&.sp-error:hover {
			color: $color-selected-text !important;
		}

		.sp-cavern-inventory-table-row-error {
			height: 0;
			left: 0;
			overflow: hidden;
			padding-right: 1em;
			padding-top: 1em;
			position: absolute;
			top: 0;
			width: 0;
		}

		.sp-cavern-inventory-table-row-error::after {
			border-right: 1em solid transparent;
			border-top: 1em solid $color-error;
			content: "";
			display: block;
			height: 0;
			margin-right: -1em;
			margin-top: -1em;
			width: 0;
		}
	}

	> .sp-cavern-inventory-table-footer-row {
		@include flexbox-container(row, nowrap, center, center, stretch);
		font-style: italic;
		height: 3em;
	}
}

@media print {
	.sp-cavern-inventory-table {
		@include flexbox-container(column, wrap);

		> .sp-cavern-inventory-table-header-row,
		> .sp-cavern-inventory-table-row,
		> .sp-cavern-inventory-table-footer-row {
			@include flexbox-item(column, 0, 0);
			@include flexbox-flow(row, wrap);
		}

		> .sp-cavern-inventory-table-row .sp-cavern-inventory-table-row-error {
			display: none;
		}
	}
}

@media screen {
	.sp-cavern-inventory-table {
		overflow: auto;

		> .sp-cavern-inventory-table-header-row,
		> .sp-cavern-inventory-table-row,
		> .sp-cavern-inventory-table-footer-row {
			float: left;
		}
	}
}

@mixin column-width($name, $width, $grow: 1, $align: left) {
	.sp-cavern-inventory-table > .sp-cavern-inventory-table-header-row .sp-cavern-inventory-table-column.#{$name},
	.sp-cavern-inventory-table > .sp-cavern-inventory-table-row .sp-cavern-inventory-table-column.#{$name},
	.sp-cavern-inventory-table > .sp-cavern-inventory-table-footer-row .sp-cavern-inventory-table-column.#{$name} {
		@include flexbox-item(row, $grow, 0, $width, $width, auto);
		@if $align == right {
			justify-content: flex-end;
		} @else if $align == center {
			justify-content: center;
		}
	}
}

.sp-cavern-inventory-overview {
	@include flexbox-container(column);
}

.sp-cavern-inventory-totals {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap);
	padding: 1em;

	> .sp-cavern-inventory-table-title {
		@include flexbox-item(column, 0, 0, 2em, 2em);
	}

	> .sp-cavern-inventory-table {
		@include flexbox-item(column, 0, 0);

		> .sp-cavern-inventory-table-row {
			&:nth-child(2n+0) {
				background-color: initial;
			}

			&:nth-child(3n+0) {
				background-color: $background-color-content-alternate;
			}

			&:nth-child(3n+2) {
				background-color: $color-grey-70;
			}
		}
	}

	@include column-width(cavern, 12em, 0);
	@include column-width(type, 4em, 0, right);
	@include column-width(open, 8em, 0, right);
	@include column-width(delta, 9em, 0, right);
	@include column-width(direction, 1em, 0);
	@include column-width(close, 8em, 0, right);
	@include column-width(in, 6em, 0, right);
	@include column-width(out, 6em, 0, right);
	@include column-width(adjust, 6em, 0, right);

	@include column-width(error, auto, 1, center);

	.sp-cavern-inventory-table-row .open > span,
	.sp-cavern-inventory-table-row .delta > span,
	.sp-cavern-inventory-table-row .close > span {
		font-size: 1.5em;
	}

	.sp-cavern-inventory-table-row .direction {
		> span {
			font-size: 1.5em;
		}

		> .xl-icon-up {
			color: $color-accent-3;
		}

		> .xl-icon-down {
			color: $color-accent-5;
		}
	}

	.sp-cavern-inventory-grade-table-column-edit {
		@include flexbox-container(row, nowrap, flex-start, center);
		color: $color-grey-45;
		cursor: pointer;

		.sp-icon {
			padding: .25em;
		}

		&:hover {
			color: $color-text;
		}

		&.sp-disabled {
			color: $color-grey-50;
			cursor: default;
		}
	}

	.close {
		padding-right: 6em;
	}

	.in,
	.out,
	.adjust {
		color: $color-grey-45;
	}

	.error {
		color: $color-error;
		margin-left: -12em;
	}
}

.sp-cavern-inventory-transfers {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap);
	padding: 1em;

	> .sp-cavern-inventory-table-title {
		@include flexbox-item(column, 0, 0, 2em, 2em);
	}

	> .sp-cavern-inventory-table {
		@include flexbox-item(column, 0, 0);
	}

	@include column-width(error, auto, 1, center);
	@include column-width(grade, 12em, 0);
	@include column-width(cavern, 4em, 0);
	@include column-width(start, 9em, 0);
	@include column-width(end, 9em, 0);
	@include column-width(gv, 5.5em, 0, right);
	@include column-width(temperature, 6em, 0, right);
	@include column-width(pressure, 4em, 0, right);
	@include column-width(standard-gravity, 5em, 0, right);
	@include column-width(gsv, 5.5em, 0, right);
	@include column-width(bsw, 4em, 0, right);
	@include column-width(nsv, 5.5em, 0, right);
	@include column-width(direction, 1em, 0);
	@include column-width(filler, 0, 1);
	@include column-width(edit, 1em, 0);

	.direction {
		padding-right: 1em;

		> span {
			font-size: 1em;

			&.xl-icon-in {
				color: $color-accent-3;
			}

			&.xl-icon-out {
				color: $color-accent-5;
			}
		}
	}

	.running {
		font-style: italic;
		font-weight: bold;
	}

	.sp-cavern-inventory-table-row .edit {
		color: $color-text;
		padding-left: .5em;

		&:not(:empty):hover {
			background-color: $color-accent-brand-dark;
		}
	}
}

.sp-cavern-inventory-adjustments {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap);
	padding: 1em;

	> .sp-cavern-inventory-table-title {
		@include flexbox-item(column, 0, 0, 2em, 2em);
	}

	> .sp-cavern-inventory-table {
		@include flexbox-item(column, 0, 0);
	}

	@include column-width(cavern, 8em, 0);
	@include column-width(date, 10em, 0);
	@include column-width(transfer-type, 9em, 0);
	@include column-width(gv, 6em, 0, right);
	@include column-width(gsv, 6em, 0, right);
	@include column-width(standard-gravity, 4em, 0, right);
	@include column-width(comment, 12em);
	@include column-width(edit, 1em, 0);

	.standard-gravity {
		padding-right: 4em;
	}

	.sp-cavern-inventory-table-row .edit {
		color: $color-text;
		padding-left: .5em;

		&:not(:empty):hover {
			background-color: $color-accent-brand-dark;
			cursor: pointer;

			.sp-icon {
				color: $color-selected-text;
			}
		}
	}
}

@media (max-width: 90em) {
	.sp-cavern-inventory-transfers {
		@include column-width(start, 7.5em, 0);
		@include column-width(end, 7.5em, 0);
		@include column-width(gv, 4.5em, 0, right);
		@include column-width(temperature, 5em, 0, right);
		@include column-width(pressure, 3.5em, 0, right);
		@include column-width(standard-gravity, 3.5em, 0, right);
		@include column-width(gsv, 4.5em, 0, right);
		@include column-width(bsw, 3.5em, 0, right);
		@include column-width(nsv, 4.5em, 0, right);
		@include column-width(direction, 1em, 0);

		.sp-cavern-inventory-table-column span {
			font-size: .875em;
		}
	}

	.sp-cavern-inventory-adjustments {
		@include column-width(transfer-type, 6em, 0);
		@include column-width(gv, 4.5em, 0, right);
		@include column-width(gsv, 4.5em, 0, right);

		.sp-cavern-inventory-table-column span {
			font-size: .875em;
		}
	}
}

@media (max-width: 75em) {
	.sp-cavern-inventory-totals {
		@include column-width(in, 5em, 0, right);
		@include column-width(out, 5em, 0, right);
		@include column-width(adjust, 5em, 0, right);

		.close {
			padding-right: 2em;
		}
	}
}

@media (max-width: 65em) {
	.sp-cavern-inventory-adjustments {
		@include column-width(comment, 8em);

		.standard-gravity {
			padding-right: 2em;
		}
	}

	.sp-cavern-inventory-transfers {
		@include column-width(grade, 10em, 0);
	}

	.sp-cavern-inventory-transfers .direction {
		display: none !important;
	}
}

@media print {
	.sp-cavern-inventory-totals,
	.sp-cavern-inventory-transfers,
	.sp-cavern-inventory-adjustments {
		padding: 1em 0 !important;
	}

	.sp-cavern-inventory-totals .gv-close,
	.sp-cavern-inventory-totals .gsv-close,
	.sp-cavern-inventory-totals .standard-gravity-close {
		padding-right: 0 !important;
	}

	.sp-cavern-inventory-grade-table-column-edit {
		display: none !important;
	}

	.sp-cavern-inventory-transfers .direction {
		display: none !important;
	}
}
