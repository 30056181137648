/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

.sp-dashboard {
	@include flexbox-container(column);
	padding: 2em;

	> .sp-panel {
		@include flexbox-item(column, 1, 1);

		.sp-panel-body {
			overflow: scroll;
		}
	}
}

.sp-dashboard-layers {
	@include flexbox-item(column, 1, 0);
	@include flexbox-container(column);
	margin: 1em;
	min-width: 40em;
	position: relative;
}

.sp-dashboard-layer-junctions {
	@include flexbox-item(column, 1, 0);
	@include flexbox-container(row, nowrap, space-between, center);
	min-width: 40em;
}

.sp-dashboard-junction {
	@include flexbox-item(row, 0, 0);
	@include flexbox-container(column);
	background-color: $color-grey-80;
}

.sp-dashboard-junction-pierce {
	@include flexbox-item(row, 0, 0, 22em, 22em);

	.sp-dashboard-junction-header {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(row);
		background-color: $color-grey-60;
		height: 2em;
	}

	.sp-dashboard-junction-name {
		@include flexbox-item(row, 1, 1);
		@include flexbox-container(column, nowrap, center, center);
		padding: 0 1em;

		> span {
			@include font-t(6);
		}
	}

	.sp-dashboard-junction-redundancy {
		@include flexbox-item(row, 0, 0, 2em, 2em);
		@include flexbox-container(row);
	}

	.sp-dashboard-junction-outer-container {
		@include flexbox-item(column, 1, 1);
		@include flexbox-container(row);
	}

	.sp-dashboard-caverns {
		@include flexbox-item(row, 1, 1);
		@include flexbox-container(column);
	}

	.sp-dashboard-cavern {
		@include flexbox-item(column, 1, 1, 8em, 8em);
		@include flexbox-container(row, nowrap, flex-start, center);
	}

	.sp-dashboard-cavern-info {
		@include flexbox-item(row, 1, 1);
		background-color: $color-white;
		box-sizing: border-box;
		margin: .5em 0 .5em .5em;
		padding: 0 1em 1em;
	}

	.sp-dashboard-cavern-info-row {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(row, nowrap, flex-start, center);
		padding-top: .5em;
	}

	.sp-dashboard-cavern-info-name {
		> span {
			@include font-t(6);
		}
	}

	.sp-dashboard-cavern-info-grade {
		> span {
			@include font-t(8);
		}
	}

	.sp-dashboard-cavern-info-gsv,
	.sp-dashboard-cavern-info-standard-gravity {
		@include flexbox-container(row, nowrap, space-between);

		> span:first-child {
			@include font-t(7);
		}

		> span:last-child {
			@include font-t(8);
			text-align: right;
		}
	}

	.sp-dashboard-unknown {
		color: $color-grey-50;
	}

	.sp-dashboard-cavern-pipe {
		@include flexbox-item(row, 0, 0, 5em, 5em, stretch);
		height: 100%;
		position: relative;
		z-index: 1;
	}

	.sp-dashboard-cavern-pipe-horizontal,
	.sp-dashboard-cavern-pipe-vertical {
		background-color: $color-grey-60;
		position: absolute;
	}

	.sp-dashboard-cavern-pipe-horizontal {
		height: 1em;
		top: calc(50% - .5em);
		width: 5em;
	}

	.sp-dashboard-cavern-pipe-vertical {
		bottom: 0;
		right: 0;
		top: 0;
		width: 1em;
	}

	.sp-dashboard-cavern:first-child .sp-dashboard-cavern-pipe-vertical {
		top: 50%;
	}

	.sp-dashboard-cavern:last-child .sp-dashboard-cavern-pipe-vertical {
		bottom: 50%;
	}

	.sp-dashboard-meter-run {
		@include flexbox-container(column, nowrap, center, center);
		height: 2em;
		left: 1em;
		top: calc(50% - 1em);
		width: 2em;
		z-index: 1;
	}

	.sp-dashboard-cavern-devices {
		@include flexbox-item(row, 0, 0, 2.5em, 2.5em);
		@include flexbox-container(column, nowrap, center);
		padding: .5em;
	}

	.sp-dashboard-device {
		@include flexbox-item(column, 0, 0, 2.5em, 2.5em);
		cursor: pointer;
		margin: .25em 0;
	}
}

.sp-dashboard-junction-genoa,
.sp-dashboard-junction-speed {
	.sp-dashboard-junction-header {
		@include flexbox-item(column, 0, 0, 4em, 4em);
		@include flexbox-container(row);
		background-color: $color-grey-60;
	}

	.sp-dashboard-junction-name {
		@include flexbox-item(row, 1, 1);
		@include flexbox-container(column, nowrap, center, center);

		> span {
			@include font-t(6);
		}
	}

	.sp-dashboard-junction-outer-container {
		@include flexbox-item(column, 1, 0);
		@include flexbox-container(column);
		margin-bottom: 2em;
	}

	.sp-dashboard-junction-flow {
		@include flexbox-item(column, 0, 0, 1.5em, 1.5em);
		@include flexbox-container(column, nowrap, center, center);
		position: relative;

		> span {
			font-size: 2em;
			position: relative;
			z-index: 1;
		}
	}

	.sp-dashboard-junction-flow-101 > span {
		bottom: -.25em;
	}

	.sp-dashboard-junction-flow-102 > span {
		top: -.25em;
	}

	.sp-dashboard-junction-inner-container {
		@include flexbox-item(column, 1, 0);
		@include flexbox-container(column, nowrap, center, center);
		background-color: $color-white;
		margin: .25em;
	}

	.sp-dashboard-junction-pipe {
		@include flexbox-item(column, 0, 0, 2em, 2em);
		@include flexbox-container(column, nowrap, center, center);
		margin: .5em 0;

		> div {
			@include flexbox-item(column, 0, 0, 2em, 2em);
			background-color: $color-grey-50;
			width: 1em;
			z-index: 1;
		}
	}

	.sp-dashboard-junction-devices {
		@include flexbox-item(column, 0, 0, 6em, 6em);
		@include flexbox-container(row, wrap);
		width: 6em;
	}

	.sp-dashboard-device {
		@include flexbox-item(row, 0, 0, 2.5em, 2.5em);
		box-sizing: border-box;
		cursor: pointer;
		height: 2.5em;
		margin: .25em;
	}
}

.sp-dashboard-device {
	@include flexbox-container(column);
	background-color: $color-grey-50;
	color: $color-white;
	position: relative;

	.sp-icon {
		@include flexbox-item(column, 1, 1);
		@include flexbox-container(column, nowrap, center, center);
		font-size: 1.5em;
	}

	.sp-icon.xl-icon-master,
	.sp-icon.xl-icon-warm {
		bottom: .125rem;
		font-size: .75em;
		position: absolute;
		right: .125rem;
	}
}

.sp-dashboard-meter-run {
	@include border-radius(1em);
	@include flexbox-container(column);
	background-color: $color-grey-50;
	color: $color-white;
	position: relative;

	.sp-icon {
		@include flexbox-item(column, 1, 1);
		@include flexbox-container(column, nowrap, center, center);
		font-size: 1.5em;
	}

	.sp-icon.xl-icon-down,
	.sp-icon.xl-icon-up {
		color: $color-text;
		font-size: 2em;
		left: 0;
		position: absolute;
		top: -2rem;
		transform: rotate(90deg);
	}
}

.sp-dashboard-device-flow,
.sp-dashboard-device-duty,
.sp-dashboard-meter-run-flow {
	background-color: $color-accent-6;
}

.sp-dashboard-device-no-flow,
.sp-dashboard-device-standby,
.sp-dashboard-meter-run-no-flow {
	background-color: $color-grey-50;
}

.sp-dashboard-device-alarm,
.sp-dashboard-device-unreachable,
.sp-dashboard-meter-run-unreachable {
	background-color: $color-red;
}

.sp-dashboard-layer-pipes {
	@include flexbox-container(column, nowrap, center);
	bottom: 0;
	left: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
}

.sp-dashboard-pipes {
	@include flexbox-item(column, 0, 0, 11em, 11em);
	@include flexbox-container(column, nowrap, space-between);
	margin: 2em 2.75em 0 18.25em;
}

.sp-dashboard-pipe {
	@include flexbox-container(row);
	background: repeating-linear-gradient(-45deg, $color-grey-70, $color-grey-70 15px, $color-grey-80 15px, $color-grey-80 30px);
	height: 2em;
	pointer-events: auto;
	position: relative;
}

.sp-dashboard-pipe-label {
	@include flexbox-container(column, nowrap, center, center);
	height: 2em;
	position: absolute;
	width: 50%;

	> span {
		@include font-t(10);
		color: $color-grey-50;
	}
}

.sp-dashboard-pipe-101 .sp-dashboard-pipe-label {
	top: -2em;
}

.sp-dashboard-pipe-102 .sp-dashboard-pipe-label {
	bottom: -2em;
}

.sp-dashboard-pipe-segment {
	@include flexbox-item(row, 0, 0, 50%, 50%);
	@include flexbox-container(row);
	background-color: $color-grey-60;
}

.sp-dashboard-batch {
	@include flexbox-item(row, 1, 1);
	@include flexbox-container(column);
	box-sizing: border-box;
	padding: .25em .125em;

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}
}

.sp-dashboard-pipe-segment-pjgj {
	.sp-dashboard-batch:first-child {
		margin-left: .25em;
	}

	.sp-dashboard-batch:last-child {
		margin-right: .5em;
	}
}

.sp-dashboard-pipe-segment-gjsj {
	.sp-dashboard-batch:first-child {
		margin-left: .5em;
	}

	.sp-dashboard-batch:last-child {
		margin-right: 1em;
	}
}

.sp-dashboard-batch-grade {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column, nowrap, center, center);

	> span {
		@include font-t(12);
		color: $color-white;
	}
}

.sp-dashboard-batch:nth-child(odd) .sp-dashboard-batch-grade {
	background-color: $color-accent-1;
}

.sp-dashboard-batch:nth-child(even) .sp-dashboard-batch-grade {
	background-color: $color-accent-1-dark;
}
