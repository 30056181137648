/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

.sp-customer-inventory-table-title {
	@include flexbox-container(row, nowrap, flex-start, center);

	> span:nth-child(2) {
		font-weight: bold;
	}
}

.sp-customer-inventory-table {
	> .sp-customer-inventory-table-header-row,
	> .sp-customer-inventory-table-row,
	> .sp-customer-inventory-table-footer-row {
		@include flexbox-container(row, nowrap, flex-start, stretch, stretch);
		background-color: $background-color-content;
		break-inside: avoid;
		min-height: 1.5em;
		min-width: 100%;
		page-break-inside: avoid;
	}

	.sp-customer-inventory-table-column {
		@include flexbox-item(row, 0, 0, auto, auto, auto);
		@include flexbox-container(row, wrap, flex-start, center, stretch);
		padding: .25em .25em .25em 0;

		&:first-child {
			padding: .25em .25em .25em 1em;
		}
	}

	> .sp-customer-inventory-table-header-row {
		border-bottom: 1px solid $border-color;

		> .sp-customer-inventory-table-column {
			> span {
				font-style: italic;
				padding-right: .25em;
			}

			> span:nth-child(2) {
				color: $color-grey-45;
			}
		}
	}

	> .sp-customer-inventory-table-row {
		position: relative;

		&:nth-child(2n+0) {
			background-color: $background-color-content-alternate;
		}

		&.sp-hover {
			cursor: pointer;

			&:hover {
				background-color: $background-color-selected;
				color: $color-selected-text;

				.sp-icon {
					color: $color-selected-text;
				}
			}
		}

		&.sp-error:hover {
			color: $color-selected-text !important;
		}

		.sp-customer-inventory-table-row-error {
			height: 0;
			left: 0;
			overflow: hidden;
			padding-right: 1em;
			padding-top: 1em;
			position: absolute;
			top: 0;
			width: 0;
		}

		.sp-customer-inventory-table-row-error::after {
			border-right: 1em solid transparent;
			border-top: 1em solid $color-error;
			content: "";
			display: block;
			height: 0;
			margin-right: -1em;
			margin-top: -1em;
			width: 0;
		}
	}

	> .sp-customer-inventory-table-footer-row {
		@include flexbox-container(row, nowrap, center, center, stretch);
		font-style: italic;
		height: 3em;
	}
}

@media print {
	.sp-customer-inventory-table {
		@include flexbox-container(column, wrap);

		> .sp-customer-inventory-table-header-row,
		> .sp-customer-inventory-table-row,
		> .sp-customer-inventory-table-footer-row {
			@include flexbox-item(column, 0, 0);
			@include flexbox-flow(row, wrap);
		}

		> .sp-customer-inventory-table-row .sp-customer-inventory-table-row-error {
			display: none;
		}
	}
}

@media screen {
	.sp-customer-inventory-table {
		overflow: auto;

		> .sp-customer-inventory-table-header-row,
		> .sp-customer-inventory-table-row,
		> .sp-customer-inventory-table-footer-row {
			float: left;
		}
	}
}

@mixin column-width($name, $width, $grow: 1, $align: left) {
	.sp-customer-inventory-table > .sp-customer-inventory-table-header-row .sp-customer-inventory-table-column.#{$name},
	.sp-customer-inventory-table > .sp-customer-inventory-table-row .sp-customer-inventory-table-column.#{$name},
	.sp-customer-inventory-table > .sp-customer-inventory-table-footer-row .sp-customer-inventory-table-column.#{$name} {
		@include flexbox-item(row, $grow, 0, $width, $width, auto);
		@if $align == right {
			justify-content: flex-end;
		}
		@if $align == center {
			justify-content: center;
		}
	}
}

.sp-customer-inventory-overview {
	@include flexbox-container(column);
}

.sp-customer-inventory-totals {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap);
	padding: 1em;

	> .sp-customer-inventory-table-title {
		@include flexbox-item(column, 0, 0, 2em, 2em);
	}

	> .sp-customer-inventory-table {
		@include flexbox-item(column, 0, 0);
	}

	@include column-width(grade, 12em, 0);
	@include column-width(nsv-open, 8em, 1, right);
	@include column-width(nsv-delta, 9em, 1, right);
	@include column-width(direction, 1em, 0, left);
	@include column-width(nsv-close, 8em, 1, right);
	@include column-width(nsv-in, 6em, 0, right);
	@include column-width(nsv-out, 6em, 0, right);
	@include column-width(nsv-adjust, 6em, 0, right);

	.sp-customer-inventory-table-row .nsv-open > span,
	.sp-customer-inventory-table-row .nsv-delta > span,
	.sp-customer-inventory-table-row .nsv-close > span {
		font-size: 1.5em;
	}

	.sp-customer-inventory-table-row .direction {
		> span {
			font-size: 1.5em;
		}

		> .xl-icon-up {
			color: $color-accent-3;
		}

		> .xl-icon-down {
			color: $color-accent-5;
		}
	}

	.nsv-close {
		padding-right: 8em;
	}

	.nsv-in,
	.nsv-out,
	.nsv-adjust {
		color: $color-grey-45;
	}
}

.sp-customer-inventory-transfers {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap);
	padding: 1em;

	> .sp-customer-inventory-table-title {
		@include flexbox-item(column, 0, 0, 2em, 2em);
	}

	> .sp-customer-inventory-table {
		@include flexbox-item(column, 0, 0);
	}

	@include column-width(grade, 11em, 0);
	@include column-width(customer, 8em, 0);
	@include column-width(start, 7.5em, 0);
	@include column-width(end, 7.5em, 0);
	@include column-width(batch-id, 5em, 0);
	@include column-width(gv, 4.5em, 0, right);
	@include column-width(temperature, 5em, 0, right);
	@include column-width(pressure, 4em, 0, right);
	@include column-width(standard-gravity, 3.5em, 0, right);
	@include column-width(gsv, 4.5em, 0, right);
	@include column-width(bsw, 3.5em, 0, right);
	@include column-width(nsv, 4.5em, 0, right);
	@include column-width(direction, 1em, 0);
	@include column-width(status, 5em, 0);
	@include column-width(origin, 7em, 0);
	@include column-width(filler, 0, 1);
	@include column-width(edit, 1.5em, 0, center);
	@include column-width(copy, 1.5em, 0, center);
	@include column-width(change-use, 1.5em, 0, center);

	.sp-customer-inventory-table-column span {
		font-size: .875em;
	}

	.direction {
		padding-right: 1em;

		> span {
			font-size: 1em;

			&.xl-icon-in {
				color: $color-accent-3;
			}

			&.xl-icon-out {
				color: $color-accent-5;
			}
		}
	}

	.running {
		font-style: italic;
		font-weight: bold;
	}

	.sp-customer-inventory-table-row .edit,
	.sp-customer-inventory-table-row .copy,
	.sp-customer-inventory-table-row .change-use {
		color: $color-text;
		padding: 0;

		&:not(:empty):hover {
			background-color: $color-accent-brand-dark;
		}
	}
}

.sp-customer-inventory-adjustments {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap);
	padding: 1em;

	> .sp-customer-inventory-table-title {
		@include flexbox-item(column, 0, 0, 2em, 2em);
	}

	> .sp-customer-inventory-table {
		@include flexbox-item(column, 0, 0);
	}

	@include column-width(grade, 12em, 0);
	@include column-width(customer, 8em, 0);
	@include column-width(date, 9em, 0);
	@include column-width(transfer-type, 8em, 0);
	@include column-width(gsv, 6em, 0, right);
	@include column-width(bsw, 4em, 0, right);
	@include column-width(nsv, 6em, 0, right);
	@include column-width(comment, 12em);
	@include column-width(filler, 0, 1);
	@include column-width(edit, 1em, 0);
	@include column-width(copy, 1em, 0);
	@include column-width(change-use, 1em, 0);

	.nsv {
		padding-right: 4em;
	}

	.sp-customer-inventory-table-row .edit,
	.sp-customer-inventory-table-row .copy,
	.sp-customer-inventory-table-row .change-use {
		color: $color-text;
		padding-left: .5em;

		&:not(:empty):hover {
			background-color: $color-accent-brand-dark;
			cursor: pointer;

			.sp-icon {
				color: $color-selected-text;
			}
		}
	}
}

@media (max-width: 90em) {
	.sp-customer-inventory-transfers {
		@include column-width(grade, 8em, 0);
		@include column-width(customer, 5em, 0);
		@include column-width(batch-id, 4.5em, 0);
		@include column-width(gv, 4em, 0, right);
		@include column-width(temperature, 4.5em, 0, right);
		@include column-width(pressure, 3em, 0, right);
		@include column-width(standard-gravity, 3em, 0, right);
		@include column-width(gsv, 4em, 0, right);
		@include column-width(bsw, 3em, 0, right);
		@include column-width(nsv, 4em, 0, right);
		@include column-width(status, 4.5em, 0);
		@include column-width(origin, 6em, 0);

		.sp-customer-inventory-table-column span {
			font-size: .75em;
		}
	}
}

@media (max-width: 75em) {
	.sp-customer-inventory-totals {
		@include column-width(grade, 6em, 1);
		@include column-width(nsv-in, 5em, 0, right);
		@include column-width(nsv-out, 5em, 0, right);
		@include column-width(nsv-adjust, 5em, 0, right);

		.nsv-close {
			padding-right: 2em;
		}
	}
}

@media (max-width: 65em) {
	.sp-customer-inventory-adjustments {
		@include column-width(grade, 6em, 1);
		@include column-width(transfer-type, 6em, 0);
		@include column-width(gsv, 4.5em, 0, right);
		@include column-width(bsw, 4.5em, 0, right);
		@include column-width(nsv, 4.5em, 0, right);
		@include column-width(comment, 8em);

		.nsv {
			padding-right: 2em;
		}

		.sp-customer-inventory-table-column span {
			font-size: .875em;
		}
	}

	.sp-customer-inventory-transfers {
		@include column-width(start, 4em, 0);
		@include column-width(end, 4em, 0);
	}

	.sp-customer-inventory-transfers .direction {
		display: none !important;
	}
}

@media print {
	.sp-customer-inventory > .sp-panel {
		margin: 0 !important;
	}

	.sp-customer-inventory-totals,
	.sp-customer-inventory-transfers,
	.sp-customer-inventory-adjustments {
		padding: 1em 0 !important;
	}

	.sp-customer-inventory-totals .nsv-close {
		padding-right: 0 !important;
	}

	.sp-customer-inventory-transfers .direction {
		display: none !important;
	}

	.sp-customer-inventory-table-column:first-child {
		padding-left: 0 !important;
	}
}
