/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

.sp-report-email-dialog {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column);

	.sp-report-email-dialog-row {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(column);
		margin: 1em 1em 0;

		&:last-child {
			margin-bottom: 1em;
		}

		> .sp-input-label {
			@include flexbox-item(column, 1, 0);
		}

		.sp-check-box-group-input {
			height: 20em;
			max-height: 20em;
		}
	}
}

.sp-report-email-check-box-text {
	@include flexbox-container(column);
	padding-bottom: .5em;
	padding-top: .25em;

	> div:first-child {
		@include font-t(7);
	}

	> div:last-child {
		@include font-t(12);
		font-style: italic;
	}
}
