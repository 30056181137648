/**
 * Copyright © 2018, ABB Switzerland Ltd.
 */

.sp-update-use-fields-dialog {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column);
	padding: 1em;

	> p {
		padding-bottom: 1em;
	}
}
